import $ from 'jquery';
function ExpandOnHover(elem) {
  let $elem = $(elem);
  $elem.each((idx, post) => {
    let projectTimer;
    $(post).on('mouseenter', function () {
      let $this = $(this);
      projectTimer = setTimeout(() => {
        $($this).addClass('expanded');
        if (idx % 2 === 0) {
          $($this).next().addClass('shrunk');
        } else {
          $($this).prev().addClass('shrunk');
        }
      }, 300);
    });
    $(post).on('mouseleave', function () {
      clearTimeout(projectTimer);
      $elem.removeClass('expanded');
      $elem.removeClass('shrunk');
    });
  });
}

export default ExpandOnHover;
