import $ from 'jquery';
import ExpandOnHover from './ExpandOnHover';

function Pagination(args = {}) {
  let containerClass = args.container ? args.container : '.isotope-projects';
  let itemClass = args.item ? args.item : '.project';
  let $container = $(containerClass);
  let $items = $container.find(itemClass);

  let postsPerPage = args.postsPerPage ? args.postsPerPage : 6;
  let currentPage = 0;

  function showPosts(posts, page = 0) {
    currentPage = page;
    $container.html('');

    let start = page * postsPerPage;
    let end = start + postsPerPage;
    let postsToShow = posts.slice(start, end);
    ExpandOnHover(postsToShow);

    $container.append(postsToShow);
    pagination(posts);
  }

  function pagination(items = $items) {
    let totalPages = Math.ceil(items.length / postsPerPage);
    if (totalPages < 2) return;
    let lastPage = totalPages - 1;

    function goToPage(page) {
      showPosts(items, page);
      let offsetTop = $container.offset().top - 300;
      $('html, body').animate({ scrollTop: offsetTop }, 300);
    }

    let $pagination = $('<ul class="pagination"></ul>');
    $pagination.html('');
    for (let i = 0; i < totalPages; i++) {
      let $paginationItem = $('<li class="show-for-medium"></li>');
      let $paginationLink = $('<a href="javascript:;">' + (i + 1) + '</a>');
      $paginationLink.on('click', function () {
        goToPage(i);
      });
      if (currentPage === i) $paginationLink.addClass('current');
      $paginationItem.append($paginationLink);
      $pagination.append($paginationItem);
    }
    let $paginationNextLink = $(
      '<a href="javascript:;" class="next"><i class="fal fa-arrow-right"></i></a>'
    ).on('click', () => {
      goToPage(currentPage + 1);
    });

    let $paginationPrevLink = $(
      '<a href="javascript:;" class="prev"><i class="fal fa-arrow-left"></i></a>'
    ).on('click', () => {
      goToPage(currentPage - 1);
    });

    if (currentPage < lastPage) {
      $pagination.append(
        $('<li class="pagination-next"></li>').append($paginationNextLink)
      );
    }
    if (currentPage > 0) {
      $pagination.prepend($('<li></li>').prepend($paginationPrevLink));
    }
    $container.append($pagination);
  }

  showPosts($items);
  $('.category-filter__link').on('click', function (e) {
    e.preventDefault();
    if ($(this).hasClass('is-active')) {
      $(this).removeClass('is-active');
      showPosts($items);
    } else {
      $('.category-filter__link').removeClass('is-active');
      $(this).addClass('is-active');
      let filterValue = $(this).data('filter');
      let filteredPosts = $items.filter((i, elem) => {
        return $(elem).hasClass(filterValue);
      });
      showPosts(filteredPosts);
    }
  });

  $('.js-category-filter').on('change', function () {
    let filterValue = $(this).val();
    if (filterValue === 'all') {
      showPosts($items);
    } else {
      let filteredPosts = $items.filter((i, elem) => {
        return $(elem).hasClass(filterValue);
      });
      showPosts(filteredPosts);
    }
  });
  $(window).on('changed.zf.mediaquery', function () {
    showPosts($items);
    $('.category-filter__link').removeClass('is-active');
  });
}

export default Pagination;
