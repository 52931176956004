// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; import './autoload/lazyload.js'; // eslint-disable-line
import $ from 'jquery';
// Import local dependencies
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import './gsap/gsapAnimations';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
import Masonry from 'masonry-layout';
import Foundation from './autoload/foundation';
import Accordion from 'accordion-js';
import lottie from 'lottie-web';
// import './isotopePagination';
import Pagination from './Pagination';
import ExpandOnHover from './ExpandOnHover';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import AOS from 'aos';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
function isScrolledIntoView(elem) {
  let $elem = $(elem);
  let $window = $(window);
  let docViewTop = $window.scrollTop();
  let elemTop = $elem.offset().top;
  let $windowHeight = $window.outerHeight();
  return docViewTop + $windowHeight / 2 >= elemTop;
}

// function hideEl(el) {
//   $(el).hide();
// }

// function testimonialsSingleProject(el, findImg, adding = true) {
//   let img = $(el).siblings('.top-section').find(findImg);
//   let imgSrc = $(img).attr('src');
//   $(el).find('.testimonials-image-animate').attr('src', imgSrc);
//   if (adding) {
//     $(el)
//       .find('.testimonials-image-animate')
//       .css('display', 'block')
//       .removeClass('animate-logo-single-revert')
//       .addClass('animate-logo-single');
//   } else {
//     $(el)
//       .find('.testimonials-image-animate')
//       .removeClass('animate-logo-single')
//       .addClass('animate-logo-single-revert');
//     setTimeout(hideEl($(el).find('.testimonials-image-animate')), 6000);
//   }
// }
//
// function animateTestimonials(arr) {
//   arr.each(function (idx, el) {
//     if (isScrolledIntoView($(el))) {
//       testimonialsSingleProject(el, '.project-icon', true);
//     } else {
//       testimonialsSingleProject(el, '.project-icon', false);
//     }
//   });
// }

function textSlideIn(arr) {
  arr.each(function (idx, el) {
    if (isScrolledIntoView($(el))) {
      if ($(el).attr('data-text-slide')) {
        $(el).find('p').removeClass('text-slide-out');
        $(el).find('p').addClass('text-slide-in');
        $(el).find('p').css({
          transform: 'translateX(0)',
          opacity: '1',
        });
      }
    } else {
      if ($(el).attr('data-text-slide')) {
        $(el).find('p').removeClass('text-slide-in');
        $(el).find('p').addClass('text-slide-out');
        $(el).find('p').css({
          transform: 'translateX(-5%)',
          opacity: '0.5',
        });
      }
    }
  });
}

$('.section').each(function (idx, el) {
  if ($(el).attr('data-text-slide')) {
    $(el).find('p').addClass('slide-class');
  }
});

let projects = $('.single-projects').find('.project-testimonials');
projects.each(function (idx, el) {
  if ($('body').hasClass('single-projects')) {
    $(el).append('<img class="testimonials-image-animate" src="" alt="">');
  }
});

let blogCards = $('.blog-main').find('article');

$(document).on('ready', function () {
  const $menuItemServices = $('.menu-item-services');
  const $megaMenu = $('.mega-menu');
  const $header = $('header');

  function isLargeScreen() {
    return window.innerWidth > 1365; // Check screen width
  }

  function showMegaMenu() {
    if (isLargeScreen()) {
      $header.addClass('service-open');
      $menuItemServices.addClass('is-open');
      $megaMenu.stop(true, true).fadeIn(200); // Smooth appearance
    }
  }

  function hideMegaMenu() {
    if (isLargeScreen()) {
      $header.removeClass('service-open');
      $menuItemServices.removeClass('is-open');
      $megaMenu.stop(true, true).fadeOut(200); // Smooth hiding
    }
  }

  // Show the menu when hovering over .menu-item-services
  $menuItemServices.on('mouseenter', showMegaMenu);

  // Hide the menu when the cursor leaves .menu-item-services and .mega-menu
  $menuItemServices.on('mouseleave', function (e) {
    if (isLargeScreen() && !$megaMenu.is(e.relatedTarget)) {
      hideMegaMenu();
    }
  });

  $megaMenu.on('mouseenter', showMegaMenu);

  $megaMenu.on('mouseleave', function (e) {
    if (isLargeScreen() && !$menuItemServices.is(e.relatedTarget)) {
      hideMegaMenu();
    }
  });

  // Monitor window resize and forcibly hide the menu if the screen becomes smaller than 1366px
  $(window).on('resize', function () {
    if (!isLargeScreen()) {
      hideMegaMenu();
    }
  });

  $('.single-projects')
    .find('.project-category__link')
    .on('click', function (e) {
      e.preventDefault();
    });

  // Hide video before uploading
  const $videoElement = $('.video--local');
  if ($videoElement.length) {
    $videoElement.on('canplaythrough', function () {
      $(this).removeClass('hidden');
    });
  }

  $('.star').on('mouseover', function () {
    const index = $(this).index(); // Get the index of the current star
    $('.star').each(function (i) {
      $(this).toggleClass('active', i <= index); // Highlight stars up to the current one
    });
  });

  $('.container').on('mouseleave', function () {
    // When the mouse leaves, the state remains as it was on the last hover
    const activeStars = $('.star.active').length; // Count active stars
    $('.star').each(function (i) {
      $(this).toggleClass('active', i < activeStars); // Keep them active
    });
  });

  // Click handler for stars
  $('.star').on('click', function () {
    const index = $(this).index(); // Get the star index (0 for the 1st star, 4 for the 5th)

    if (index >= 3) {
      // If 4 or 5 stars are selected (index 3 or 4), show the second section
      $('.stars-section, .form-section').hide();
      $('.google-section').show();

      $('html, body').animate(
        { scrollTop: $('.form-section').offset().top },
        500
      );
    } else {
      // If 3 or fewer stars are selected, show the third section
      $('.stars-section, .google-section').hide();
      $('.form-section').show();

      $('html, body').animate(
        { scrollTop: $('.form-section').offset().top },
        500
      );

      let elem = $('.gform_button');
      if (isCentered($(elem))) {
        $(elem).addClass('js-is-centered');
      }
      let buttonWidth = $(elem).outerWidth();
      let buttonText = $(elem).text();
      let hoverText = 'Get started';
      $(elem).outerWidth(buttonWidth);
      let hoverTimeout;
      let leaveTimeout;

      $(elem).on('mouseover', function () {
        let $this = $(this);
        var compStyle = window.getComputedStyle(this, ':after');
        var newWidth = compStyle.width;
        $this.outerWidth(newWidth);
        // $this.text(hoverText);
        if ($this.hasClass('js-is-centered')) {
          let widthDiff = (parseFloat(newWidth) - parseFloat(buttonWidth)) / 2;

          $this.css('transform', 'translateX(' + widthDiff + 'px)');
        }

        clearTimeout(leaveTimeout);
        hoverTimeout = setTimeout(function () {
          $this.text(hoverText);
        }, 100);
      });

      $(elem).on('mouseleave', function () {
        let $this = $(this);
        clearTimeout(hoverTimeout);
        leaveTimeout = setTimeout(function () {
          $this.text(buttonText);
        }, 100);
        $this.outerWidth(buttonWidth);
        $this.css('transform', 'none');
      });
    }
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  $('.feature .hover-wrap .image-wrap').matchHeight();
  $('.feature .hover-wrap .title').matchHeight();

  $('.why-choose-wrap .why-choose__item .image-wrap').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  let navImg = document.querySelector('.nav_logo');
  let ulElement = document.querySelector(
    'ul[data-responsive-menu="accordion large-dropdown"][data-submenu-toggle="true"][data-multi-open="false"][data-close-on-click-inside="false"]'
  );
  if (navImg && ulElement) {
    ulElement.appendChild(navImg);
  }

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  AOS.init();
  //$('.accordion-menu li').attr('data-aos', 'fade-up');

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function () {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function (element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function (i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function () {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Appear one by one submenu items
   */

  $('.submenu li').each(function (index) {
    var delay = index * 0.1 + 's';
    $(this).css('animation-delay', delay);
  });
  $('.accordion-menu .menu-item a')
    .not('.submenu a')
    .each(function (index) {
      var delay = 0.4 * (index + 1) + 's';
      $(this).css('animation-delay', delay);
    });
  $('.submenu-toggle')
    .not('.submenu a')
    .each(function (index) {
      var delay = 0.4 * (index + 1) + 's';
      $(this).css('animation-delay', delay);
    });
  // const items = document.querySelectorAll('.submenu li');
  // items.forEach((item, index) => {
  //   item.style.animation = `0.6s linear ${index * 0.2}s alternate left-move`;
  // });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
      $('.header').toggleClass('mobile-active');
      $('.header').find('.top-bar').slideToggle('300');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
      $('.header').removeClass('mobile-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  // Anchor tabs
  const tabContent = function (el) {
    let contentDiv = el
      .closest('.grid-x')
      .find('.post-content')
      .find('.entry__content');
    let headers = contentDiv.find($('h1,h2,h3,h4,h5,h6,strong'));
    headers.each(function () {
      let idText = $(this)
        .text()
        .toLowerCase()
        .replace(/[^a-z0-9\s]/gi, '')
        .replace(/[_\s]/g, '-');
      $(this).attr('id', idText);
    });
    // console.log($('*:contains(' + el.find('a').text() + ')'));
    // let idText = .text().toLowerCase().replace(/ /g, '_');
    $(el.find('a')).each(function () {
      let idText = $(this)
        .text()
        .toLowerCase()
        .replace(/[^a-z0-9\s]/gi, '')
        .replace(/[_\s]/g, '-');
      $(this).attr('href', '#' + idText);
    });
  };
  tabContent($('.anchors-wrapper'));

  $('.anchors-tabs__link').on('click', function () {
    let anchor = $(this).attr('href');
    $('html, body')
      .stop()
      .animate(
        {
          scrollTop: $(anchor).offset().top - 70,
        },
        1500
      );
  });
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  buttonAnimation();
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  resizeVideo();
});
document.cookie = 'screen-width=' + screen.width;

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
  // animateTestimonials(projects);
  blogCards.each(function (idx, el) {
    if (isScrolledIntoView($(el))) {
      $(el).find('.preview__thumb').addClass('thumb-animate');
    } else {
      $(el).find('.preview__thumb').removeClass('thumb-animate');
    }
  });

  textSlideIn($('.section'));

  if ($(this).scrollTop() > 200) {
    $('.hero-icon').addClass('hero-icon-rotate');
  } else {
    $('.hero-icon').removeClass('hero-icon-rotate');
  }
});

if (!$('.isotope-projects').length) {
  ExpandOnHover('.project');
} else {
  Pagination();
}

$('.reviews-slider').slick({
  fade: true,
  arrows: true,
  dots: true,
  slidesToShow: 1,
  rows: 0,
  adaptiveHeight: true,
  appendArrows: '.reviews-slider__arrows',
  responsive: [
    {
      breakpoint: 1366,
      settings: {
        dots: false,
      },
    },
  ],
});

function isCentered($elem) {
  let elemHalf = $elem[0].getBoundingClientRect().width / 2;
  let windowCenter = Math.floor($(window).width() / 2);
  let parentHalf = $elem.parent()[0].getBoundingClientRect().width / 2;
  let elemCenter = Math.floor($elem.offset().left + elemHalf);
  let parentCenter =
    Math.floor($elem.parent().offset().left) + Math.floor(parentHalf);

  return Math.abs(elemCenter - parentCenter) < 2 || elemCenter === windowCenter;
}

function buttonAnimation() {
  $(
    '.button:not(.gform_button, .not-get, .home-hero__item .button, .project__button, .featured-work__link a, .text-under .button, .section-design-features .button, .cta .button, .why-choose__link, .section-enhancements .button)'
  ).each(function (i, elem) {
    if (isCentered($(elem))) {
      $(elem).addClass('js-is-centered');
    }
    let buttonWidth = $(elem).outerWidth();
    let buttonText = $(elem).text();
    let hoverText = 'Get started';
    $(elem).outerWidth(buttonWidth);
    let hoverTimeout;
    let leaveTimeout;

    $(elem).on('mouseover', function () {
      let $this = $(this);
      var compStyle = window.getComputedStyle(this, ':after');
      var newWidth = compStyle.width;
      $this.outerWidth(newWidth);
      // $this.text(hoverText);
      if ($this.hasClass('js-is-centered')) {
        let widthDiff = (parseFloat(newWidth) - parseFloat(buttonWidth)) / 2;

        $this.css('transform', 'translateX(' + widthDiff + 'px)');
      }

      clearTimeout(leaveTimeout);
      hoverTimeout = setTimeout(function () {
        $this.text(hoverText);
      }, 100);
    });

    $(elem).on('mouseleave', function () {
      let $this = $(this);
      clearTimeout(hoverTimeout);
      leaveTimeout = setTimeout(function () {
        $this.text(buttonText);
      }, 100);
      $this.outerWidth(buttonWidth);
      $this.css('transform', 'none');
    });
  });
}

$('.masonry-wrapper').each(function (i, elem) {
  let masonryGrid;
  let isActive = false;

  $(window).on('load changed.zf.mediaquery', function () {
    if (Foundation.MediaQuery.is('small down')) {
      if (isActive) {
        masonryGrid.destroy();
      }
    } else {
      masonryGrid = new Masonry(elem, {
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true,
        // horizontalOrder: true,
      });
      isActive = true;
    }
  });
});

if ($('.faq').length) {
  let acc;
  let isInit = false;
  $(window).on('load changed.zf.mediaquery', function () {
    if (Foundation.MediaQuery.is('medium down')) {
      let options = {
        duration: 300,
        elementClass: 'js-acc-item',
        triggerClass: 'acc-head',
        panelClass: 'acc-panel',
      };
      acc = new Accordion('.js-acc', options);
      isInit = true;
    } else {
      if (isInit) {
        acc.destroy();
        isInit = false;
      }
    }
  });
}

$('.lottie-anim').each((i, item) => {
  let path = $(item).data('source');

  let lottieAnimation = lottie.loadAnimation({
    container: item,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path,
  });

  lottieAnimation.addEventListener('data_ready', function () {
    ScrollTrigger.create({
      trigger: item,
      once: true,
      start: 'top 80%',
      onEnter: () => {
        let maxFrames = lottieAnimation.getDuration(true) / 2;
        lottieAnimation.playSegments([0, maxFrames], true);
      },
    });
  });
});

let $pagination = $('ul.pagination li');
$pagination.each((i, item) => {
  if ($(item).find('.next').length) {
    $(item).addClass('pagination-next');
  }
});

$(window).on('load changed.zf.mediaquery', function () {
  let copyText = $('.footer__copyright p').html();
  if (Foundation.MediaQuery.is('small down')) {
    $('.footer__copyright p').html(copyText.replace('|', '<br>'));
    $pagination.each((i, item) => {
      if (!$(item).find('.prev').length && !$(item).find('.next').length) {
        $(item).hide();
      }
    });
  } else {
    $('.footer__copyright p').html(copyText.replace('<br>', '|'));
  }
});

// function isOverflow(item) {
//   return item.offsetWidth < item.scrollWidth;
// }
//
// $(window).on('load resize', () => {
//   $('.breadcrumbs-item').each((i, item) => {
//     let $itemLink = $(item).find('a').length ? $(item).find('a') : $(item);
//     const itemText = $itemLink.attr('title');
//     $itemLink.text(itemText);
//     if (isOverflow(item)) {
//       $itemLink.text('...');
//     }
//   });
// });

function selectmenuInit() {
  $('select.gfield_select').each(function (i, elem) {
    let $container = $(elem).closest('.ginput_container_select');
    $(elem).selectmenu({
      appendTo: $container,
      change: function () {
        $(this).trigger('change');
      },
    });
  });
}

selectmenuInit();

$(document).on('gform_post_render', function () {
  selectmenuInit();
});

$('.js-category-filter').each((i, elem) => {
  let $filterContainer = $(elem).parent();

  $(elem).selectmenu({
    appendTo: $filterContainer,
    change: function () {
      $(this).trigger('change');
    },
  });
});

$('.cta').each((idx, item) => {
  ScrollTrigger.create({
    trigger: item,
    once: true,
    start: 'top 30%',
    onEnter: () => {
      $(item).addClass('show');
    },
  });
});

if ($('.price-plan__accordion').length) {
  new Accordion('.price-plan__accordion .acc-container', {
    duration: 300,
    elementClass: 'acc-item',
    triggerClass: 'acc-item__head',
    panelClass: 'acc-item__panel',
  });
}
