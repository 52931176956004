import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import Foundation from '../autoload/foundation';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

let heroSlides = gsap.utils.toArray('.home-hero .home-hero__item');
let currentSlide = heroSlides[0];

let tl = gsap.timeline({
  scrollTrigger: {
    trigger: '.home-hero',
    scrub: true,
    pin: true,
    start: () => {
      if (document.getElementById('wpadminbar')) {
        let start = 'top top';
        if (window.innerWidth > 600) {
          start = 'top 46px';
        }
        if (window.innerWidth > 782) {
          start = 'top 32px';
        }

        return start;
      }
      return 'top top';
    },
    // end: 'bottom top',
    onToggle: (self) => {
      if (self.trigger) {
        if (self.isActive) {
          makeHeaderInactive();
        } else {
          makeHeaderActive();
        }
      }
    },
  },
});

$('.bouncing-arrow').on('click', function () {
  let progress = tl.progress().toFixed(3);
  if (tl.nextLabel() && progress < 0.999) {
    let pos = tl.scrollTrigger.labelToScroll(tl.nextLabel());
    gsap.to(window, {
      scrollTo: pos,
      duration: 1,
    });
  } else {
    let section = $('main.home-page').find('.section')[0].offsetTop - 50;
    gsap.to(window, {
      scrollTo: section,
      duration: 1,
    });
  }
});

function makeHeaderActive() {
  if (!$('.header').hasClass('is-active')) {
    $('.header').addClass('is-active');
    showToggler();
  }
}

function makeHeaderInactive() {
  if ($('.header').hasClass('is-active')) {
    $('.header').removeClass('is-active');
    hideToggler();
  }
}

function setSection(newSection) {
  if (newSection !== currentSlide) {
    tl.to(currentSlide, { scale: 0.8, autoAlpha: 0 });
    tl.to(newSection, { scale: 1, autoAlpha: 1 });
    tl.addLabel('label');
    currentSlide = newSection;
  }
}

heroSlides.forEach((section) => {
  setSection(section);
});

$(window).on('load changed.zf.mediaquery', function () {
  if (Foundation.MediaQuery.is('small down')) {
    let whyChooseItems = gsap.utils.toArray('.why-choose__item');
    whyChooseItems.forEach((item) => {
      gsap.from(item, {
        scrollTrigger: {
          start: 'top 80%',
          // end: 'bottom 20%',
          trigger: item,
          toggleClass: 'is-active',
        },
      });
    });
    let introItems = gsap.utils.toArray('.feature');
    introItems.forEach((item) => {
      gsap.from(item, {
        scrollTrigger: {
          start: 'top 80%',
          // end: 'bottom 20%',
          trigger: item,
          toggleClass: 'is-active',
        },
      });
    });
    let introItemsEnhancement = gsap.utils.toArray('.enhancement');
    introItemsEnhancement.forEach((item) => {
      gsap.from(item, {
        scrollTrigger: {
          start: 'top 80%',
          // end: 'bottom 20%',
          trigger: item,
          toggleClass: 'is-active',
        },
      });
    });
    let introItemsLocal = gsap.utils.toArray('.section-local-seo .item');
    introItemsLocal.forEach((item) => {
      gsap.from(item, {
        scrollTrigger: {
          start: 'top 80%',
          // end: 'bottom 20%',
          trigger: item,
          toggleClass: 'is-active',
        },
      });
    });
    let introIcons = gsap.utils.toArray('.intro-icons__item');
    introIcons.forEach((item) => {
      gsap.from(item, {
        scrollTrigger: {
          start: 'top 80%',
          // end: 'bottom 20%',
          trigger: item,
          toggleClass: 'is-active',
        },
      });
    });
    let icons = gsap.utils.toArray('.icons-content__icon');
    icons.forEach((icon) => {
      gsap.from(icon, {
        x: -50,
        autoAlpha: 0,
        scrollTrigger: {
          trigger: icon,
          start: 'top 80%',
        },
      });
    });
    let iconsCustom = gsap.utils.toArray('.icons-content__custom');
    iconsCustom.forEach((icon) => {
      gsap.from(icon, {
        x: -50,
        autoAlpha: 0,
        scrollTrigger: {
          trigger: icon,
          start: 'top 80%',
        },
      });
    });
  } else {
    // For desctop
    let fadeUpContainers = gsap.utils.toArray('.beige-columns .grid-container');
    fadeUpContainers.forEach((container) => {
      gsap.from(container, {
        y: 50,
        autoAlpha: 0,
        duration: 1,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: container,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      });
    });

    let sbsCells = gsap.utils.toArray('.sbs-content .cell');
    sbsCells.forEach((cell, index) => {
      gsap.from(cell, {
        x: index % 2 === 0 ? -100 : 100,
        autoAlpha: 0,
        duration: 1,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: cell,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      });
    });

    let wrapperColumns = gsap.utils.toArray(
      '.section-columns__wrapper .section-columns__column'
    );
    wrapperColumns.forEach((cell, index) => {
      gsap.from(cell, {
        x: index % 2 === 0 ? -100 : 100,
        autoAlpha: 0,
        duration: 1,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: cell,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      });
    });

    gsap.from(
      '.sbs-content-text-list .cell-with-title, .sbs-content-text-list .large-7',
      {
        x: -200,
        autoAlpha: 0,
        duration: 1,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: '.sbs-content-text-list',
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      }
    );

    gsap.from('.sbs-content-text-list .list-box', {
      x: 200,
      autoAlpha: 0,
      duration: 1,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: '.sbs-content-text-list',
        start: 'top 80%',
        toggleActions: 'play none none none',
      },
    });

    gsap.utils.toArray('.items-wrap .item').forEach((item, index) => {
      gsap.from(item, {
        opacity: 0,
        y: 50,
        duration: 0.8,
        delay: index * 0.1,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: item,
          start: 'top 80%',
          end: 'bottom top',
          toggleActions: 'play none none none',
        },
      });
    });

    gsap.utils
      .toArray('.enhancements-wrap .enhancement')
      .forEach((item, index) => {
        gsap.from(item, {
          opacity: 0,
          y: 50,
          duration: 0.8,
          delay: index * 0.1,
          ease: 'power3.out',
          scrollTrigger: {
            trigger: item,
            start: 'top 80%',
            end: 'bottom top',
            toggleActions: 'play none none none',
          },
        });
      });

    let introSection = gsap.utils.toArray('.intro-icons');
    introSection.forEach((section) => {
      gsap.from(section, {
        scrollTrigger: {
          start: 'top 50%',
          end: 'bottom 20%',
          trigger: section,
          toggleClass: 'is-active',
        },
      });
    });

    let icons = gsap.utils.toArray('.icons-content__icon');
    let iconsTl = gsap.timeline({
      scrollTrigger: {
        trigger: '.icons-content',
      },
    });
    icons.forEach((icon) => {
      iconsTl.from(icon, {
        y: -50,
        autoAlpha: 0,
      });
    });

    let iconsCustom = gsap.utils.toArray('.icons-content__custom');
    let iconsTlCustom = gsap.timeline({
      scrollTrigger: {
        trigger: '.icons-content',
      },
    });
    iconsCustom.forEach((icon) => {
      iconsTlCustom.from(icon, {
        y: -50,
        autoAlpha: 0,
      });
    });
  }
});

function hideToggler() {
  gsap.to('.desktop-nav-toggler', {
    x: 40,
    width: 0,
    duration: 0.3,
  });
  $('.desktop-nav-toggler').removeClass('is-active');
}

function showToggler() {
  gsap.to('.desktop-nav-toggler', {
    x: 0,
    width: 40,
    duration: 0.3,
  });
}

if (!$('.home-hero').length) {
  let heroHeight = 2;

  $(window).on('load scroll', function () {
    let scrollTop = $(window).scrollTop();

    if (scrollTop < heroHeight) {
      makeHeaderInactive();
    } else {
      makeHeaderActive();
    }
  });
} else {
  $(window).on('load', () => {
    if (!tl.scrollTrigger.isActive) {
      makeHeaderActive();
    }
  });
}

$('.desktop-nav-toggler').on('click', function () {
  if ($(this).hasClass('is-active')) {
    $(this).removeClass('is-active');
  } else {
    $(this).addClass('is-active');
  }
});
